.circle-img {
  background-color: #b2dbfa;
  height: 200px;
  width: 200px;
  border: none;
  border-radius: 50%;
  box-shadow: 0 3px 5px 0 #4d5f6d, 0 2px 7px 0 #596d7c;
  object-fit: cover;
  animation: float 4s ease-in-out infinite;
}

.circle-img:hover {
  cursor: pointer;
}

.circle-img:active {
  cursor: pointer;
}

@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-30px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
}

@media (max-width: 1024px) {
 .circle-img {
  height: 150px;
  width: 150px;
  animation:paused;
 } 

 .circle-img:hover {
  box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
  transform: translatey(-10px);
 }

 .circle-img:active {
  box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
  transform: translatey(0px);
 }
}

@media (max-width:540px ) {
  .circle-img {
   height: 125px;
   width: 125px;
  }  
}


@media (max-width:375px ) {
  .circle-img {
   height: 100px;
   width: 100px;
  }  
}

@media (max-width:280px ) {
  .circle-img {
   height: 80px;
   width: 80px;
  }  
}

@media (max-width: 1024px) and (max-height: 600px) {

}