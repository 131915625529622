
.about {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: #90c2e7;
  position: relative;
  font-family: "Inter Regular", BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.about-container {
  max-height: 90%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: auto;
}

.about-text-and-link {
  display: flex;
  flex-direction: column;
  width: 600px;
  max-width: 30vw;
}

/* hidden elements */
/* cards */
#about-hidden {
  display: none;
}

/* visible elements */
#about-visible {
  display: flex;
  width: 100%;
}

#about-img-visible {
  display: initial;
}

/* resized elements */
#about-general-img-large {
  height: 400px;
  width: 400px;
  animation: none;
}

#about-coding-img-large {
  height: 400px;
  width: 400px;
  animation: none;
}

#about-cooking-img-large {
  height: 400px;
  width: 400px;
  animation: none;
}

#about-crafting-img-large {
  height: 400px;
  width: 400px;
  animation: none;
}


@media (max-width: 1024px) {
  .about-container {
    height: 80%;
    width:100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  #divFlipped {
    height: 80%;
    width: 100%;
    flex-direction: column;
   justify-content: center;
    align-items: center;
    gap: 25px;
  }

  /* resized elements */
#about-general-img-large {
  height: 300px;
  width: 300px;
}

#about-coding-img-large {
  height: 300px;
  width: 300px;
}

#about-cooking-img-large {
  height: 300px;
  width: 300px;
}

#about-crafting-img-large {
  height: 300px;
  width: 300px;
}

/* visible elements */
#about-visible {
  /* width: 400px; */
  max-width: 80%;
  align-items: center;
}
}

@media (max-width: 540px) {
      /* resized elements */
  #about-general-img-large {
    height: 175px;
    width: 175px;
  }

  #about-coding-img-large {
    height: 175px;
    width: 175px;
  }
  
  #about-cooking-img-large {
    height: 175px;
    width: 175px;
  }
  
  #about-crafting-img-large {
    height: 175px;
    width: 175px;
  }
  
}

@media (max-width: 414px) {
    /* resized elements */
#about-general-img-large {
  height: 200px;
  width: 200px;
}

#about-coding-img-large {
  height: 200px;
  width: 200px;
}

#about-cooking-img-large {
  height: 200px;
  width: 200px;
}

#about-crafting-img-large {
  height: 200px;
  width: 200px;
}


#divFlipped {
  height: 80%;
  justify-content: flex-end;
  gap: 25px;
}
}

@media (max-width:375px ) {
    /* resized elements */
    #about-general-img-large {
      height: 150px;
      width: 150px;
    }
    
    #about-coding-img-large {
      height: 150px;
      width: 150px;
    }
    
    #about-cooking-img-large {
      height: 150px;
      width: 150px;
    }
    
    #about-crafting-img-large {
      height: 150px;
      width: 150px;
    }

    #divFlipped {
      height: 80%;
      justify-content: flex-end;
      gap: 25px;
    }
}

@media (max-width: 1024px) and (max-height: 700px) {
    /* resized elements */
    #about-general-img-large {
      height: 150px;
      width: 150px;
    }
    
    #about-coding-img-large {
      height: 150px;
      width: 150px;
    }
    
    #about-cooking-img-large {
      height: 150px;
      width: 150px;
    }
    
    #about-crafting-img-large {
      height: 150px;
      width: 150px;
    }

    .about-container {
      flex-direction: row;
    }
}

