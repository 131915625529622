.Portfolio {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background-color: #6CBAC1;
  color: #654f6f;
  font-family: "Inter Regular", BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}


/* front css */
.front {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

/* front hidden */
.hide-front {
  display: none;
}
/**/

.front-topRow {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 5% 0;
}

.sqr-img-div {
  height: 300px;
  width: 550px;
  border: 2px solid #0d5c63;
}

.sqr-img-div:hover {
  cursor: pointer;
  box-shadow: 0 4px 8px 0 #0d5c63, 0 3px 10px 0 #0d5c63;
  transform: translateY(-4px);
}

.sqr-img-div:active {
  box-shadow: none;
  transform: translateY(4px);
}

/* back css */
.back {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow-y: auto;
}

/* back hidden */
.hide-back {
  display: none;
}
/**/

.back-content {
  height: 85%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
  align-items: center;
  margin-top: 5vh;
}

.back-and-about {
  max-height: 70%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 75px;
  align-items: flex-start;
  /* margin-top: 30vh; */
  margin-bottom: 10vh;
}

.back-walkthrough {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  height: 100%;
}

.player-wrapper {
  width: 80%;
  height: 100%;
  position:relative;
  
}

.react-player {
  position:absolute;
  top:0;
  left:0;
}

.back-description {
  max-height: fit-content;
  max-width: 30%;
  display: flex;
  flex-direction: column;
}

#back-about {
  margin-top: 10px;
}

#back-btn {
  margin-bottom: 40px;
}

@media (max-width: 1024px) {
  .sqr-img-div {
    height: 225px;
    width: 413px; 
  }
}

@media (max-width: 912px) {
  .front-topRow {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 90%;
    height: 90%;
  }

  .front-topRow {
    justify-content: center;
    align-items: center;
    gap: 15px;
  }
}

@media (max-width: 540px) {
  .sqr-img-div {
    height: 188px;
    width: 344px; 
  }
}
@media (max-width: 912px) {
  .back-content {
    justify-content: flex-start;
    height: 80%;
  }

  .back-and-about {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* margin-bottom: 10vh; */
    gap: 1.75em;
  }

  .back-walkthrough {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .player-wrapper {
    width: 100%;
    height: 100%;
    margin-top: 0.75em;
    position:relative;
  }

  #back-btn {
  width: 80px;
  height: 40px;
  margin-bottom: 1em;
  font-size: 0.7em;
  }

  .back-description {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

}

@media (max-width: 280px) {
  .sqr-img-div {
    height: 112px;
    width: 206px; 
  }
}


