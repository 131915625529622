nav {
  display: flex;
  width: 100vw;
  height: 60px;
  margin: 0;
  padding: 0;
  position: fixed;
  background-color: transparent;
}

.left-nav-bar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 0 25px;
  padding: 0;
  gap: 30px;
  border: none;
  height: 100%;
  width: 50%;
}



@media (max-width: 768px) {
  .left-nav-bar > .nav-link {
    display: none;
  }
  .home-hamburger {
    color: white;
  }
  #home-hamburger {
    background-color: transparent;
  }
}

.right-nav-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 0 20px 0 0;
  padding: 0;
  border: none;
  height: 100%;
  width: 50%;
}

@media (max-height: 480px), (max-width: 320px) {
  nav {
    height: 10%;
   align-items: center;
  }

  .right-nav-bar {
    margin: 0;
  }

  .left-nav-bar {
    margin: 0;
  }
}