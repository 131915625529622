
.Contact {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background-color: #654f6f;
  font-family: "Inter Regular", BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    overflow-y: hidden;
}

.contact-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.contact-content > p {
  color: #ffffff;
}

@media (max-width: 414px) {
  .contact-content {
    width: 80%;
    margin: auto;
  }
}