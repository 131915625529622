.profile-img {
  position: relative;
  background-color: #ffffff;
  height: 750px;
  width: 550px;
  /* margin-left: 2%; */
  border: none;
  border-radius: 75% / 75%;
  box-shadow: 0 3px 10px 0 #4d5f6d, 0 2px 20px 0 #596d7c;
  object-fit: cover;
}

.profile-img:hover {
  cursor: pointer;
}

.profile-img:active {
  cursor: pointer;
}

@media (max-width: 1310px), (max-height: 900px) {
  .profile-img {
    height: 656px ;
    /* 
    375px *
    281.25px *
    187.5px *
    93.75px *
    */
    width: 481px;
    /* 
    275px
    206.25px
    137.5px
    68.75px
    */
  }
}

@media (max-width: 925px) {
  .profile-img {
    height: 563px;
    width: 413px
  }
}

@media (max-width: 795px) {
  .profile-img {
    height: 275px;
    width: 344px
  }
}

@media (max-width: 360px) {
  .profile-img {
    height: 137.5px;
    width: 187.5px;
  }
}

/* At 768px the navbar switches to a hamburger menu, so will change the home-content to column insteadof row. */