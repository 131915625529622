.square-img {
  display: block;
  min-height: 100%;
  max-height: 100%;
  min-width: 100%;
  max-width: 100%;
  object-fit: fill;
}


