.icon-img {
  height: 40px;
  padding: 10px;
  margin: 10px 0 0 0;
}


@media (max-height: 480px), (max-width: 320px){
  .icon-img {
    height: 25px;
    margin: 5px 0 0 0;
    padding: 5px;
  }
}