a {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
  list-style-type: none;
  padding: 3px;
  margin: 0;
  text-decoration: none;
}

a:hover {
  cursor: pointer;
  color: #b48291;
}
