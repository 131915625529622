.hamburger {
  display: none;
}

.hamburger-dropbtn {
  padding-right: 1em;
  padding-left: 1em;
  background-color: transparent;
  color: #fff;
  border: none;
}

.hamburger-dropbtn:hover {
  cursor: pointer;
}

.hamburger-content {
  position: absolute;
  background-color: none;
  min-width: 150px;
  z-index: 1;
  padding: 0;
}

.hidden-hamburger-content {
  display: none;
}

.hamburger-content p {
  color: #fff;
  margin: 0;
  padding: 0;
  display: block;
}

.hamburger-content p:hover {
  color: #b48291;
}

.portfolio-hamburger {
  color: #000;
}

/* media queries */
@media (max-width: 768px) {
  .hamburger {
    position: relative;
    display: inline-block;
  }
}

@media (max-height: 480px) {
.left-nav-bar {
  margin: 0 0 0 10px;

}

  .hamburger-dropbtn {
    padding-right: 0;
    padding-left: 0;
  }

  .hamburger-content p {
    font-size: 0.5em;
    padding: 5px 0 0 0;
    margin: 0;
  }

  .hamburger-content a {
    padding: 0;
  }
}