* {
  font-family: "Inter Regular", BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

#contact-form {
  display: flex;
  flex-direction: column;
  width: 400px;
  max-width: 80%;
  gap: 10px;
  background-color: #fff;
  padding: 1em 3em 1em 3em;
  font-weight: 100;
  margin: 0;
  align-items: center;
}

#contact-form > * {
  margin: 0;
  width: 80%;
}

#contact-form > input {
  font-size: 0.7em;
  padding: 0.4em;
  border: none;
  background-color: #eff2f4;
}

#contact-form > textarea {
  min-height: 150px;
  resize: none;
  font-size: 0.7em;
  padding: 0.4em;
  border: none;
  background-color: #eff2f4;
  margin-bottom: 1em;
}

#contact-form > button {
  width: 100px;
}

#contact-form > h4 {
  text-align: center;
  padding-bottom: 1em;
  font-size: 1.2em;
  font-weight: 500;
}

@media (max-width: 414px) {
  #contact-form {
    max-width: 100%;
    gap: 10px;
    background-color: #fff;
    padding: 0.5em;
    font-weight: 100;
    margin: 0;
    align-items: center;
  }
}