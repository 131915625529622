.btn {
  background: #b48291;
  color: #ffffff;
  width: 175px;
  height: 45px;
  padding: 0.7em;
  border: none;
  box-shadow: 0 4px 8px 0 #61454e, 0 3px 10px 0 #6e4f59;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: small;
  letter-spacing: 0.6px;
}

.btn:hover {
  background-color: #c38d9d;
  cursor: pointer;
}

.btn:active {
  background-color: #c38d9d;
  box-shadow: 0 2px 3px 0 #61454e, 0 1px 3px 0 #6e4f59;
  transform: translateY(4px);
}
