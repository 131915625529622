.about-card {
  background-color: #ffffff;
  color: #654f6f;
  width: fit-content;
  max-width: 100%;
  height: fit-content;
  padding: 0.5em 2em;
  border: none;
  border-radius: 5px;
  box-shadow: 0 8px 16px 0 #4d4a4b, 0 6px 20px 0 #5a5758;
  text-align: left;
}

h3 {
  margin: 0;
  margin-top: 2px;
}

@media (max-width: 1024px) {
  .about-card {
    width: 70%;
    height: 100%;
    font-size:0.75em;
  }
}

@media (max-width: 540px) {
  .about-card {
    font-size: 0.7em;
  }
}

@media (max-width: 414px) {
  .about-card {
    font-size: 0.65em;
  }
}

@media (max-width:375px ) {
  .about-card {
    width: 90%;
    height: 100%;
    font-size:0.6em;
  } 
}

@media (max-width:280px ) {
  .about-card {
    width: 90%;
    height: 100%;
    font-size:0.5em;
  } 
}
