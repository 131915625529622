
.Home {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background-color: #0d5c63;
  font-family: "Inter Regular", BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.home-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
}

.home-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  margin-right: 30px;
}


.home-left {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 80%;
  margin-left: 30px;
  margin-top: 2em;
}

.home-name-pixal {
  font-family: "SH Pinscher", sans-serif;
  font-size: 4em;
}

.home-name-cursive {
  font-family: "Great Vibes", cursive;
  font-size: 3.92em;
}

.home-summary {
  margin-bottom: 10vh;
  color: #ffffff;
  text-decoration: none;
}

.home-summary:hover {
  margin-bottom: 10vh;
  color: #ffffff;
}

@media(max-width: 1310px) {
  .home-content {
    gap: 100px;
  }
}

@media (max-width:768px) {
  .home-content {
    flex-direction: column;
    justify-content:center;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    gap: 0;
  }

  .home-left {
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin: 3em 0 0 0;
  }

  .home-right {
    margin: 0;
    height: 50%;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  #mobile-size-h1 {
    font-size: 3em;
    margin: 0;
  }

  .home-summary {
    font-size: 1.25em;
    margin: 0 0 2em 0;
  }
}

@media (max-width: 414px) {
  .home-content {
    gap: 3em;
  }

  .home-left {
    height: 30%;
    margin: 3em 0 0 0;
  }

  .home-right {
    height: 40%;
    width: 90%;
  }

  #mobile-size-h1 {
    font-size: 2em;
    margin: 0;
  }

  .home-summary {
    font-size: 1em;
    margin: 1em 0 2em 0;
    padding: 0 0 0 1em;
    width: 60%;
    justify-content: center;
  }
}
