.project-title {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

h2 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  color: #fff;
  text-decoration: underline;
  text-transform: none;
  padding: 0;
  margin: 0;
}

h2:hover {
  color: #B48291;
}


@media (max-width: 540px) {
  h2 {
    font-size: 1.25em;
  }
}